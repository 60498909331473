import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { buildDataDownloadUrl, buildHeaderDownloadUrl } from 'state-domains/network';

import { sendFileDownloadRequest } from '../../../utils';

export const downloadFile = (
    fileGroupId: string,
    fileId: string,
    fileName: string,
    header: boolean,
): Observable<any> => {
    const url = header
        ? buildHeaderDownloadUrl(fileGroupId, fileId)
        : buildDataDownloadUrl(fileGroupId, fileId);
    return sendFileDownloadRequest(url).pipe(
        switchMap((blob: Blob) => observableOf({ blob, fileName })),
    );
};
